(function($) {

    $(document).ready(function() {

       $('body').addClass('loaded');
        var shrinkHeader = 100;
        $(window).scroll(function() {
            var scroll = getCurrentScroll();
            //console.log(scroll);
            //var st = $(this).scrollTop();
            if (scroll >= shrinkHeader) {
                $('body').addClass('shrink');
            } else {
                $('body').removeClass('shrink');
            }
        });


        $('a').each(function () {
          var a = new RegExp('/' + window.location.host + '/');
          if (!a.test(this.href)) {
            if (this.href.startsWith('http')) $(this).attr("target", "_blank");
          }
        });

        // Check for click events on the navbar burger icon
        $(".navbar-burger").click(function() {
          console.log('ja bin da');
            // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
            $(".navbar-burger").toggleClass("is-active");
            $(".navbar-menu").toggleClass("is-active");

        });

    });
})(jQuery);

function getCurrentScroll() {
    return window.pageYOffset || document.documentElement.scrollTop;
}
